<template>
  <HEAD>
    <META http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <META name="description" content="云思shat软件家族软件下载" />
    <META name="keywords" content="云思shat软件家族软件下载" />
    <META http-equiv="X-UA-Compatible" content="IE=7" />
    <TITLE>云思shat软件家族下载</TITLE>
    <LINK href="css/CSS.css" rel="stylesheet" type="text/css" />
  </HEAD>

  <BODY>
    <div id="bdy3">
      <div id="bdy4">
        <div id="head" class="w12">
          <div id="homepage"></div>

          <div style="padding-top: 40px">
            <strong>全国统一服务热线：17347318127</strong>
          </div>
        </div>

        <DIV id="navv">
          <DIV id="nav">
            <DIV class="nav1"><A href="/dist/index.html">首 页</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="index.html#/download" @click="go_link();">模拟考场</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav3"><A href="/dist/index.html#/download">下载中心</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/price">软件价格</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/sell">购买方式</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
            <DIV class="nav1"><A href="/dist/index.html#/about">联系我们</A></DIV>
            <DIV class="nav2"><img src="../images/04.gif" /></DIV>
          </DIV>
        </DIV>

        <DIV id="tmain2">
          <DIV id="tm2_1"><img src="../images/32.png" /></DIV>
          <DIV id="cont_left"  :style="height">
            <DIV id="tm2_2_1"><H2>客服中心</H2></DIV>
            <DIV class="cont_left_1_6">
              <UL id="m3_02_body">
                <LI id="m3_02_bodyimg2"></LI>

                <LI class="m3_02_bodyfont4">客服小云 QQ:896216279</LI
                ><LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=896216279&Site=软件客服&Menu=yes"
                    ><img
                      src="../ico/pa.gif"
                      alt="896216279"
                      hspace="2"
                      border="0"
                      align="absmiddle" /></a
                ></LI>
                <LI class="m3_02_bodyfont4">客服Cessary QQ:382599652</LI
                ><LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=382599652&Site=软件客服&Menu=yes"
                    ><img
                      src="../ico/pa.gif"
                      alt="382599652"
                      hspace="2"
                      border="0"
                      align="absmiddle" /></a
                ></LI>
                <LI class="m3_02_bodyfont4"> </LI>
                <LI id="m3_02_bodyimg3"></LI>
                <LI class="m3_02_bodyfont2">渠道小云 QQ:896216279</LI>
                <LI class="m3_02_bodyfont4"
                  ><a
                    target="blank"
                    href="tencent://message/?uin=896216279&Site=云思SHAT软件家族&Menu=yes"
                  >
                    <img
                      src="../ico/pa.gif"
                      hspace="2"
                      border="0"
                      align="middle"
                      width="74"
                      height="23" /></a
                ></LI>
                <LI class="m3_02_bodyfont4"> </LI>
                <LI id="m3_02_bodyimg4"></LI>

                <LI class="m3_02_bodyfont2">咨询热线：17347318127 </LI>
                <LI class="m3_02_bodyfont3">投诉：ctaco@foxmail.com</LI>
                <LI class="m3_02_bodyfont3">客服在线时间：周一至周日</LI>
                <DIV style="clear: both"></DIV>
              </UL>
            </DIV>
            <DIV id="tm2_2_3"><img src="../images/bg2.gif" /></DIV>
          </DIV>
          <DIV id="cont_right">
            <DIV id="tm2_3" style="margin-bottom: 15px; height: auto">
              <DIV id="tm2_3_1">
                <DIV id="tm2311"><H2>试用下载</H2></DIV>
              </DIV>

              <DIV class="tm2_3_2" id="show_21">
                <p>&nbsp;</p>
                <table
                  width="509"
                  height="170"
                  border="1"
                  align="center"
                  cellpadding="0"
                  cellspacing="0"
                  bordercolor="#A3AFD6"
                  bgcolor="#F7FCFD"
                  style="border-collapse: collapse"
                >
                  <tr>
                    <td width="121" rowspan="4">
                      <div align="center">
                        <img
                          border="0"
                          src="../gn/cta.png"
                          width="80"
                          height="80"
                        />
                      </div>
                    </td>
                    <td height="31">
                      <div align="center">
                        <strong
                          ><img
                            src="../images/ico13.gif"
                            width="19"
                            height="19"
                            align="absmiddle"
                          />
                          <font size="4" color="#FF6600">云思助手工具下载</font
                          >&nbsp; 免费下载试用</strong
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td height="32">
                      <div align="center">
                        <strong>
                          <img src="../ico/94.gif" width="8" height="7" /></strong
                        >版本： 最新&nbsp;&nbsp; 大小：6.33M
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td height="75">
                      <div align="center">
                        <table width="100%%" border="0">
                          <tr>
                            <td>
                              <div align="center">
                                <a
                                  target="_blank"
                                  href="http://www.sellod.cn/upload/cta.zip"
                                  ><img
                                    border="0"
                                    src="../images/download_btn.png"
                                    width="137"
                                    height="49"
                                /></a>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div align="center"></div>
                    </td>
                  </tr>
                </table>
                <p>&nbsp;</p>
                <!-- <table
                  width="509"
                  height="170"
                  border="1"
                  align="center"
                  cellpadding="0"
                  cellspacing="0"
                  bordercolor="#A3AFD6"
                  bgcolor="#F7FCFD"
                  style="border-collapse: collapse"
                >
                  <tr>
                    <td width="121" rowspan="4">
                      <div align="center">
                        <img
                          border="0"
                          src="../gn/CTH.png"
                          width="80"
                          height="80"
                        />
                      </div>
                    </td>
                    <td height="31">
                      <div align="center">
                        <strong
                          ><img
                            src="../images/ico13.gif"
                            width="19"
                            height="19"
                            align="absmiddle"
                          />
                          <font size="4" color="#FF6600">云思互助工具下载</font
                          >&nbsp; 免费下载试用</strong
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td height="32">
                      <div align="center">
                        <strong>
                          <img src="../ico/94.gif" width="8" height="7" /></strong
                        >版本： 最新&nbsp;&nbsp; 大小：2.1M
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td height="75">
                      <div align="center">
                        <table width="100%%" border="0">
                          <tr>
                            <td>
                              <div align="center">
                                <a
                                  target="_blank"
                                  href="http://www.sellod.cn/sellod/upload/cta.zip"
                                  ><img
                                    border="0"
                                    src="../images/download_btn.png"
                                    width="137"
                                    height="49"
                                /></a>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div align="center"></div>
                    </td>
                  </tr>
                </table> -->
              </DIV>
            </DIV>
          </DIV>
          <DIV id="tm2_4" class="blu" style="overflow: hidden; height: 220px">
            <DIV id="m4_2_new">
              ICP许可证编号：<a href="https://beian.miit.gov.cn" target="_blank"
                >湘ICP备2021008170号-1</a
              >
              © 2021-2025 sellod.cn 版权所有
            </DIV>
          </DIV>
        </DIV>
      </div>
    </div>
  </BODY>
</template>

<script>
import "../css/CSS.css";

export default {
  data() {
    return {
      height: {
            height: window.innerHeight-200 + 'px'
        },
      height_left: {
          height: window.innerHeight-292 + 'px'
      },
    };
  },
  methods:{
    go_link(){
      var param="width="+window.screen.width * window.devicePixelRatio+",height="+window.screen.height * window.devicePixelRatio+",top=0,left=0,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no";
      const neww = this.$router.resolve({name: 'Papers', params: {}});
      window.open(neww.href,"云思模拟考场",param);
    }
  }
};
</script>

<style>
#m4_2_new a {
  color: #6b8191;
}
</style>
